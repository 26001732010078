import {
    faCircleXmark,
    faFile,
    faFloppyDisk,
    faMoon as farMoon,
    faStar as farStar,
} from "@fortawesome/free-regular-svg-icons";
import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCccPaypal,
    faCcDinersClub,
    faTwitter,
    faFacebook,
    faInstagram,
    faYoutube,
    faCcPaypal,
} from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCarBurst,
    faCloudArrowDown,
    faUserSecret,
    faIndustry,
    faSadTear,
    faUpDownLeftRight,
    faCog,
    faLocationCrosshairs,
    faTachometerAlt,
    faEdit,
    faTrash,
    faPlus,
    faUserPlus,
    faUserCircle,
    faSave,
    faBook,
    faBed,
    faCheck,
    faPowerOff,
    faBomb,
    faExclamationCircle,
    faInfoCircle,
    faTriangleExclamation,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faChevronCircleRight,
    faCalendarDay,
    faTimes,
    faSpinner,
    faTrashRestore,
    faCreditCard,
    faLock,
    faSignal,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryThreeQuarters,
    faBatteryEmpty,
    faLockOpen,
    faBell,
    faDoorOpen,
    faSkullCrossbones,
    faHistory,
    faListUl,
    faUser,
    faHome,
    faBars,
    faCloudDownloadAlt,
    faCompass,
    faSearchLocation,
    faSearch,
    faRulerHorizontal,
    faMoon,
    faCheckCircle,
    faSignInAlt,
    faSignOutAlt,
    faRotateRight,
    faKey,
    faTruck,
    faHammer,
    faUserTie,
    faSuitcase,
    faUmbrellaBeach,
    faQuestion,
    faBroom,
    faPassport,
    faCashRegister,
    faFileInvoice,
    faLink,
    faHotel,
    faMoneyBillAlt,
    faUsers,
    faArrowLeft,
    faUndoAlt,
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faFilter,
    faPaperPlane,
    faUserGroup,
    faChartPie,
    faDownload,
    faClipboard,
    faClipboardCheck,
    faArchway,
    faHandHoldingDollar,
    faMap,
    faMapMarkerAlt,
    faStar,
    faStarHalf,
    faStarHalfStroke,
    faBan,
    faDiagramProject,
    faBuilding,
    faEnvelope,
    faPhone,
    faFilePdf,
    faVideo,
    faWeight,
    faXmark,
    faGear,
    faPen,
} from "@fortawesome/free-solid-svg-icons";
// import {
// } from "@fortawesome/free-"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
library.add(
    faUserSecret,
    faIndustry,
    faCog,
    faTachometerAlt,
    faEdit,
    faTrash,
    faPlus,
    faUserPlus,
    faUserCircle,
    faSave,
    faBook,
    faBed,
    faCheck,
    faPowerOff,
    faBomb,
    faExclamationCircle,
    faTriangleExclamation,
    faCircleXmark,
    faChevronDown,
    faChevronUp,
    faChevronCircleRight,
    faCalendarDay,
    faTimes,
    faSpinner,
    faTrashRestore,
    faCreditCard,
    faLock,
    faSignal,
    faBatteryFull,
    faBatteryHalf,
    faBatteryQuarter,
    faBatteryThreeQuarters,
    faBatteryEmpty,
    faLockOpen,
    faBell,
    faDoorOpen,
    faSkullCrossbones,
    faHistory,
    faListUl,
    faUser,
    faHome,
    faBars,
    faCloudDownloadAlt,
    faCompass,
    faSearchLocation,
    faSearch,
    faRulerHorizontal,
    faMoon,
    faCheckCircle,
    farMoon,
    faSignInAlt,
    faSignOutAlt,
    faRotateRight,
    faKey,
    faTruck,
    faHammer,
    faUserTie,
    faSuitcase,
    faUmbrellaBeach,
    faQuestion,
    faBroom,
    faPassport,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDinersClub,
    faCashRegister,
    faFileInvoice,
    faLink,
    faHotel,
    faMoneyBillAlt,
    faUsers,
    faArrowLeft,
    faUndoAlt,
    faAngleLeft,
    faAngleRight,
    faAnglesLeft,
    faAnglesRight,
    faFilter,
    faPaperPlane,
    faUserGroup,
    faChartPie,
    faDownload,
    faClipboard,
    faClipboardCheck,
    faArchway,
    faHandHoldingDollar,
    faChevronLeft,
    faChevronRight,
    faMap,
    faMapMarkerAlt,

    faTwitter,
    faFacebook,
    faInstagram,
    faYoutube,
    faStar,
    faCcPaypal,
    faBan,
    faDiagramProject,
    faBuilding,
    faEnvelope,
    faPhone,
    faFilePdf,
    faVideo,
    faWeight,
    faInfoCircle,
    faSadTear,
    faStarHalfStroke,
    faStarHalf,
    farStar,
    faLocationCrosshairs,
    faCloudArrowDown,
    faXmark,
    faFloppyDisk,
    faGear,
    faCarBurst,
    faPen,
    faUpDownLeftRight
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
